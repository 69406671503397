import $ from "jquery";

$(window).on("resize", resizeBodyPaddingForNavbar);
$(window).on("load", function () {
   resizeBodyPaddingForNavbar();
   $('.navbar button').on('click', () => $("#navbar-overlay").toggle());
   $("#navbar-overlay").on('click', () => $('.navbar button').trigger("click"));
   addIubenda();
});

function resizeBodyPaddingForNavbar() {
   var navbarHeight = parseInt($('#navbar').css("height"));
   $('body').css('padding-top', navbarHeight);
}

function addIubenda() {
   const iubImports = `
      <!--Start Cookie Banner-->
      <script type="text/javascript">
         var _iub = _iub || [];
         _iub.csConfiguration = { "askConsentAtCookiePolicyUpdate": true, "enableFadp": true, "enableLgpd": true, "enableUspr": true, "fadpApplies": true, "floatingPreferencesButtonDisplay": "bottom-right", "lang": "en", "perPurposeConsent": true, "siteId": 3498310, "usprApplies": true, "whitelabel": false, "cookiePolicyId": 22290540, "banner": { "acceptButtonDisplay": true, "closeButtonDisplay": false, "customizeButtonDisplay": true, "explicitWithdrawal": true, "listPurposes": true, "position": "float-top-center", "rejectButtonDisplay": true, "showTitle": false } };
      </script>
      <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3498310.js"></script>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js"></script>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
      <!--End Cookie Banner-->
   `;
   $("head").append(iubImports);
}